

































import { defineComponent, toRefs, PropType, unref } from '@vue/composition-api';
import groupBy from 'lodash/groupBy';

import type { ListMenuSection } from '../types';

const ListMenu = defineComponent({
  name: 'ListMenu',
  props: {
    sections: {
      type: Array as PropType<ListMenuSection[]>,
      required: false,
      default: () => [],
    },
  },
  setup: (props) => {
    const { sections } = toRefs(props);

    const menuSectionGroups = Object.entries(
      groupBy(unref(sections), (section) => section.groupId)
    );

    return {
      menuSectionGroups,
    };
  },
});

export default ListMenu;
